.landing {
  height: 100vh;
  background-color: var(--blue);
  display: flex;
  align-items: center;
}

.landing__bg {
  position: absolute;
  top: 0;
  left: 0;
}

.landing__container {
  z-index: 100;
  position: relative;
}

.landing h1 {
  font-size: 38pt;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.landing h1 span {
  text-decoration: underline var(--main);
  text-underline-offset: 0.6rem;
}

.landing__buttons a {
  text-decoration: none;
  color: white;
  border: 1px solid var(--main);
  padding: 1rem 1.8rem;
  margin-right: 1.4rem;
  border-radius: 0.4rem;
  transition: 0.3s;
  font-weight: 400;
  background-color: #0e003f80;
  white-space: nowrap;
  line-height: 5rem;
}

.landing__buttons a:hover {
  background-color: var(--main);
}

.landing__stats {
  margin-top: 2rem;
  font-weight: 400;
  line-height: 2rem;
}

.landing__stats span {
  color: var(--green);
}

.rocket {
  position: absolute;
  top: 100vh;
  transform: translate(0, calc(-100% + 2px));
  width: 62%;
  left: 24%;
  z-index: 0;
}

.about {
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.about__coins {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about__coins img {
  width: 30vw;
  margin: 0 auto;
  display: block;
}

.about__coins h3 {
  font-size: 28pt;
  font-weight: 600;
}

.about__feathures {
  width: 50%;
}

.feathure {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
}

.feathure img {
  width: 43px;
  padding: 1rem;
}

.feathure h4 {
  margin-top: 1.6rem;
  margin-bottom: 0.4rem;
  font-weight: 600;
  font-size: large;
}

.feathure p {
  font-weight: 300;
  line-height: 22pt;
  font-size: 12pt;
  margin: 0;
}

.feathure a {
  color: var(--main);
  font-weight: 500;
}

.stake {
  background-color: var(--main);
  padding-top: 0rem;
  padding-bottom: 8rem;
}

.stake__triangle {
  display: block;
  margin: 0 auto;
  margin-bottom: 4rem;
  height: 40px;
}

.stake h2 {
  font-size: 30pt;
  margin-bottom: 3.6rem;
}

.stake__buttons a {
  text-decoration: none;
  color: white;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  padding: 1rem 1.8rem;
  margin-right: 1.4rem;
  border-radius: 0.4rem;
  transition: 0.3s;
  font-weight: 400;
  line-height: 5rem;
}

.stake__buttons a:hover {
  background-color: transparent;
}

.help {
  background-color: var(--blue);
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.help h3 {
  font-size: xx-large;
  margin-bottom: 0.8rem;
}

.help p {
  font-weight: 400;
}

.support__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
}

.support {
  width: max(calc(40% - 8rem), 280px);
  background-image: linear-gradient(#31216940, #31216980);
  padding: 2.6rem 3rem;
  border-radius: 0.8rem;
  margin-top: 2.4rem;
}

.support p {
  font-size: 10pt;
  margin: 0;
  margin-bottom: 0.4rem;
}

.support__line {
  height: 3px;
  width: 50px;
  background-color: var(--main);
}

.support__email {
  display: flex;
  align-items: center;
  justify-content: left;

  background-color: var(--blue);
  padding: 0.9rem 0.8rem;
  border-radius: 0.6rem;

  text-decoration: none;
  color: white;
  margin-top: 2.4rem;
  margin-bottom: 0.4rem;
}

.support__email img {
  margin-left: 0.3rem;
  margin-right: 1.2rem;
}

.support__discord {
  display: block;
  margin-top: 2.4rem;
}

@media only screen and (max-width: 800px) {
  .landing h1 {
    font-size: 30pt;
    font-weight: 600;
  }

  .landing h1 br {
    display: none;
  }

  .landing__stats {
    line-height: 26pt;
    font-size: 16pt;
  }

  .about {
    flex-direction: column;
  }

  .about__coins {
    width: 100%;
  }

  .about__coins img {
    width: 50vw;
  }

  .about__coins h3 {
    font-size: 30pt;
    font-weight: 600;
  }

  .about__feathures {
    width: 100%;
  }

  .feathure img {
    width: 50px;
  }

  .feathure h4 {
    font-size: x-large;
  }

  .feathure p {
    line-height: 26pt;
    font-size: 16pt;
  }

  .support__container {
    flex-direction: column;
  }

  .support {
    width: max(calc(100% - 10rem), 280px);
    background-image: linear-gradient(#31216940, #31216980);
    padding: 2.6rem 3rem;
    border-radius: 0.8rem;
    margin-top: 2.4rem;
  }
}

@media only screen and (max-width: 500px) {
  .landing__container h1 {
    font-size: 26pt;
  }

  .landing__container {
    margin-top: -4rem;
  }

  .landing__stats {
    display: none;
  }

  .planet__bottom {
    top: 75vh !important;
    right: 0vw !important;
  }

  .rocket {
    transform: translate(0, calc(-100% + 2px));
    width: 90%;
    left: 0;
  }

  .about__coins h3 br {
    display: none;
  }
  .about__coins h3 {
    text-align: center;
  }
  .landing__buttons a,
  .stake__buttons a {
    font-size: large;
  }

  .stake h2 {
    font-size: 26pt;
  }

  .feathure img {
    width: 40px;
    padding: 0;
    padding-right: 1rem;
  }

  .feathure h4 {
    margin-top: .2rem;
  }
}

@media only screen and (max-height: 700px) {
    .landing__container h1 {
      font-size: 22pt;
      margin-bottom: 1rem;
    }

    .landing__buttons a {
      font-size: large;
      padding: .8rem 1.6rem;
      line-height: 4rem;
    }
  }