@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --blue: #0E003F;
  --main: #3000E0;
  --green: #49E8C1;
}

body {
  margin: 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  background-image: linear-gradient(to bottom, var(--bg) 100%);
  overflow-x: hidden;
  color: white;
}

.safearea {
  padding: 0 11vw;
}

.app {
  min-height: 100vh;
  position: relative;
}

.hide {
  display: none;
}

/* CUSTOM SCROLLBAR */
body::-webkit-scrollbar {
  background-color: white;
  width: 10px;
}
body::-webkit-scrollbar-track {
  background-color: white;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--main);
  background-image: linear-gradient(var(--blue), var(--main));
}
body::-webkit-scrollbar-button {
  display: none;
}

.onlyMobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .noMobile {
    display: none;
  }

  .onlyMobile {
    display: block;
  }
}