header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 2.4rem 11vw;
  width: 78vw;
  z-index: 2;
}

header img {
  width: 20vw;
}

.header__social {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.header__social a {
  background-color: #231554;
  padding: .4rem;
  border-radius: .2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.header__social a:hover {
  background-color: var(--main);
}

.header__social img {
  width: 1.3rem;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(86deg) brightness(102%) contrast(105%);
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 2.4rem 11vw;
  width: 78vw;
  z-index: 2;
  color: black;
}

footer a {
  font-size: small;
  font-weight: 400;
  text-decoration: none;
  width: 30%;
  color: black;
}

footer p {
  font-size: small;
  color: black;
  font-weight: 400;
}

footer .terms {
  text-align: right;
}

footer img {
  width: 18vw;
}

@media only screen and (max-width: 800px) {
  header img {
    width: 30vw;
  }

  footer .terms {
    display: none;
  }

  footer img {
    width: 30vw;
  }
}

@media only screen and (max-width: 500px) {
  header img {
    width: 40vw;
  }

  .header__social span {
    display: none;
  }

  .header__social a {
    padding: .5rem;
    border-radius: .4rem;
    margin-left: 1.2rem;
  }

  .header__social a img {
    width: 24px;
  }

  footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  footer a, footer img {
    width: 40vw;
  }

}